@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,800;0,900;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: 'normal';
}

body::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
}

/* Overriding Date Picker library time input */
input[name='time-input'] {
  width: 92px !important;
}

a {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 768px) {
  a:focus {
    outline: none;
  }
}

input[type='text'],
input[type='number'],
textarea {
  font-size: inherit;
}

.chakra-modal__content {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 1441px) {
  .chakra-modal__content {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

@media only screen and (max-height: 730px) {
  .chakra-modal__content {
    margin-top: 6rem !important;
  }
}
